<script>
import { required } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';

import BasicDataPartial from "./partials/basic-data-partial.vue";
import DescriptionPartial from './partials/description-partial.vue';
import TermAndConditionPartial from "./partials/term-and-condition-partial.vue";

const initialState = () => {
    return {
        submitted: false,
        advantage: {
            advantage_id: null,
            logo_media_id: null,
            image_media_id: null,
            logo: null,
            image: null,
            eyca_category: null,
            eyca_category_id: null,
            product_category: null,
            product_category_id: null,
            name: null,
            english_name: null,
            description: null,
            english_description: null,
            observations: null,
            advantage_conditions: null,
            tags: null,
            eyca_tags: null,
            status: 'pending',
            featured: false,
            all_establishments: false,
            establishments: [],
        },
        activeTab:null
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number,
        collaborator: Object,
        initialActiveTab: Number,
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        BasicDataPartial,
        DescriptionPartial,
        TermAndConditionPartial,
    },
    computed: {
    },
    validations: {
        advantage: {
            advantage_id: { },
            logo: { },
            image: { },
            eyca_category_id: { },
            eyca_category: { },
            product_category_id: { },
            product_category: { },
            name: { required },
            english_name: { },
            description: { },
            english_description: { },
            observations: { },
            advantage_conditions: { },
            status: { required },
            establishments: {
                required(value) {
                    return !this.advantage?.all_establishments ? Array.isArray(value) && value.length > 0 : true;
                },
            },
        }
    },
    methods: {
        async shown() {

            if (this.id) {
                if(this.initialActiveTab){
                    this.activeTab = this.initialActiveTab;
                }
                const response = await api.get({
                    url: `collaborators/${this.collaborator?.id}/advantages/${this.id}`,
                    config: {
                        withLoading: true
                    }  
                });

                const advantage = response?.data?.data;
                this.advantage = advantage;
            }
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            const data = { ...this.advantage };
            data.image_media_id = this.advantage?.image?.id;
            data.logo_media_id = this.advantage?.logo?.id;
            data.product_category_id = this.advantage?.product_category?.id;
            data.eyca_category_id = this.advantage?.eyca_category?.id;
            data.tags = data?.tags?.map(item => item?.name)
            data.eyca_tags = data?.eyca_tags?.map(item => item?.name)
            data.establishment_ids = data.establishments.map(item => item.id);

            const response = await api.save({
                url: `collaborators/${this.collaborator?.id}/advantages`,
                id: this.advantage?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess", response?.data?.data);
        },
        hasErrorsInTab(tab) {
            
            if(tab === 'description_tab'){
                const fields = ['description', 'english_description'];
                return fields.some(field => this.$v.advantage[field].$invalid);
            }

            return false;
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de ventaja'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
        <div class="row">

            <div class="col-lg-4 col-md-12 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">Datos básicos</h4>
                        <BasicDataPartial v-model="advantage" :v="$v" :collaborator-id="collaborator?.id" :submitted="submitted"></BasicDataPartial>                        
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-6 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <b-tabs v-model="activeTab" content-class="p-3 text-muted">
                            <b-tab class="border-0">
                                <template v-slot:title>
                                    <span :class="{ 'text-danger': submitted && hasErrorsInTab('description_tab') }">
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">
                                            <i class="ri-alert-line" v-if="submitted && hasErrorsInTab('description_tab')"></i>
                                            Descripción
                                        </span>
                                    </span>                                    
                                </template>
                                <b-card-text>
                                    <DescriptionPartial v-model="advantage" :v="$v" :submitted="submitted"></DescriptionPartial>  
                                </b-card-text>
                            </b-tab>                                                                                    
                            <b-tab class="border-0">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">
                                        Términos y condiciones
                                    </span>                                   
                                </template>
                                <b-card-text>
                                    <TermAndConditionPartial v-model="advantage" :v="$v" :submitted="submitted"></TermAndConditionPartial>  
                                </b-card-text>
                            </b-tab>                             
                        </b-tabs> 
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>