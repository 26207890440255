<script>
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";
import AddIndividualMediaButton from "@/components/buttons/add-individual-media-button.vue";
import Status from "@/helpers/general/status";
export default {
    props: {
        value: Object,
        v: Object,
        collaboratorId: Number,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            advantage_status: Status.getList('advantage_status'),
        }
    },
    computed: {
        establishmentUrl() {
            return `/collaborators/${this.collaboratorId}/establishments`;
        },
        // establishmentDisabled() {
        //     return Boolean(this.value.all_establishments);
        // }
    },
    components: {        
        SelectAutocompleteField,
        AddIndividualMediaButton,
    },
    methods: {
        
    },
    // watch: {
    //     'value.all_establishments'(newVal) {
    //         if (newVal) {
    //             this.value.establishments = [];
    //         }
    //     }
    // }
};
</script>

<template>
    <div class="row">
        <div class="col-6">
            <label>Imagen destacada</label>
            <AddIndividualMediaButton v-model="value.image" :onlyImages="true" :uploadModalId="'add-individual-media-modal-image-media'"></AddIndividualMediaButton>
            <div v-if="v.advantage.image.$error && submitted" class="invalid-feedback d-block">Imagen destacada es requerida</div>
        </div>
        <div class="col-6">
            <label>Logo</label>
            <AddIndividualMediaButton v-model="value.logo" :onlyImages="true" :uploadModalId="'add-individual-media-modal-logo-media'"></AddIndividualMediaButton>
            <div v-if="v.advantage.logo.$error && submitted" class="invalid-feedback d-block">Logo es requerido</div>
        </div>
        <div class="form-group col-12">
            <label for="name">Nombre</label>
            <input id="name" v-model="value.name" type="text" class="form-control" placeholder="Ingresa un nombre"
                :class="{ 'is-invalid': v.advantage.name.$error && submitted }" />
            <div v-if="!v.advantage.name.required && submitted" class="invalid-feedback">Nombre es requerido</div>
        </div>
        <div class="form-group col-12" v-has-permission="{ permission: 'promotions.approve', action: 'remove' }">
            <label for="english_name">Nombre en inglés</label>
            <input id="english_name" v-model="value.english_name" type="text" class="form-control" placeholder="Ingresa un nombre en inglés"
                :class="{ 'is-invalid': v.advantage.english_name.$error && submitted }" />
            <div v-if="!v.advantage.english_name.required && submitted" class="invalid-feedback">Nombre en inglés es requerido</div>
        </div>
        <div class="form-group col-12">
            <label>Establecimientos</label>
            <div class="input-group">
                <!-- <div class="input-group-prepend">
                    <div class="input-group-text">
                        <label for="all-establishments" class="mb-0">Todos</label>
                        <input
                            type="checkbox"
                            class="form-controls ml-2"
                            id="all-establishments"
                            v-model="value.all_establishments"
                        />
                    </div>                    
                </div> -->
                <SelectAutocompleteField
                    :url="establishmentUrl"
                    :multiple="true"
                    :placeholder="'Busca un establecimiento'"
                    :label="'trade_name'"
                    v-model="value.establishments"
                    class="flex-grow-1"
                    :class="{ 'is-invalid': v.advantage.establishments.$error && submitted }"
                ></SelectAutocompleteField>
            </div>
            <div v-if="!v.advantage.establishments.required && submitted" class="invalid-feedback d-block">Selecciona al menos un establecimiento</div>
        </div>
        <div class="form-group col-12">
            <label>Categoría</label>
            <SelectAutocompleteField
                :url="`/product-categories`"
                :multiple="false"
                :placeholder="'Busca una categoría'"
                :label="'name'"
                v-model="value.product_category"
                :class="{ 'is-invalid': v.advantage.product_category.$error && submitted }"
            ></SelectAutocompleteField>
        </div>
        <div class="form-group col-12" v-has-permission="{ permission: 'promotions.approve', action: 'remove' }">
            <label>Categoría EYCA</label>
            <SelectAutocompleteField
                :url="`/eyca-categories`"
                :multiple="false"
                :placeholder="'Busca una categoría de la eyca'"
                :label="'name'"
                v-model="value.eyca_category"
                :class="{ 'is-invalid': v.advantage.eyca_category.$error && submitted }"
            ></SelectAutocompleteField>
        </div>
        <div class="form-group col-12" v-has-permission="{ permission: 'promotions.approve', action: 'remove' }">
            <label for="status">Estado</label>
            <select v-model="value.status" class="form-control"
                :class="{ 'is-invalid': v.advantage.status.$error && submitted }">
                <option v-for="option in advantage_status" v-bind:value="option.id" :key="option.id">
                    {{ option.label }}
                </option>
            </select>
        </div>
        <div class="form-group col-12">
            <label>Etiquetas</label>
            <SelectAutocompleteField
                :url="`/tags`"
                :multiple="true"
                :taggable="true"
                :placeholder="'Busca o crea una etiqueta'"
                :label="'name'"
                :field="'name'"
                v-model="value.tags"
            ></SelectAutocompleteField>
        </div>
        <div class="form-group col-12" v-has-permission="{ permission: 'promotions.approve', action: 'remove' }">
            <label>Etiquetas EYCA</label>
            <SelectAutocompleteField
                :url="`/tags`"
                :multiple="true"
                :taggable="true"
                :placeholder="'Busca o crea una etiqueta'"
                :label="'name'"
                :field="'name'"
                v-model="value.eyca_tags"
            ></SelectAutocompleteField>
        </div> 
        <div class="form-group col-12" v-has-permission="{ permission: 'promotions.approve', action: 'remove' }">
            <label for="featured">Destacar ventaja?</label>
            <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    v-model="value.featured"
                    class="custom-control-input"
                    id="featured"
                />
                <label class="custom-control-label" for="featured">Sí</label>
            </div>
        </div>                          
    </div> 
</template>