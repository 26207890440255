<script>
import PromotionForm from "@/views/pages/promotions/forms/promotion-form.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import AddButton from "@/components/buttons/add-button.vue";
import Status from "@/helpers/general/status";
import PromotionDiscountCodesShow from "@/views/pages/promotions/show/promotion-discount-codes-show.vue";
import FeaturedIcon from '@/components/widgets/featured-icon.vue';

/**
 * Promotions Component
 */
export default {
    props: {
        collaborator: Object
    },
    components: {
        CustomTable,
        PromotionForm,
        AddButton,
        PromotionDiscountCodesShow,
        FeaturedIcon
    },
    data() {
        return {
            showModalId: 'collaborator-promotion-show-modal',
            showDiscountCodeModalId: 'collaborator-promotion-show-modal',
            formModalId: 'collaborator-promotion-form-modal',
            table: {
                params: {
                    'with_trashed': 1,
                    'status': 'all'
                },
                fields: [
                    { key: "id", label: "Cod", sortable: true },
                    { key: "created_at", label: "Fecha de creación", sortable: true },
                    { key: "title", label: "Titulo", sortable: true },
                    { key: "creator.name", label: "Creado por", sortable: false },
                    { key: "approver.name", label: "Aprobado por", sortable: false },
                    { key: "status", label: "Estado", sortable: false },
                    { key: "featured", label: "Destacada", sortable: false },
                ],
                formatted_fields: [
                    'created_at',
                    'title',
                    'status',
                    'featured',
                ]
            },
            formSelectedItemId: null,
            showSelectedItemId: null,
            showSelectedPromotion: null,
        };
    },
    computed: {
        tableUrl() {
            return `/collaborators/${this.collaborator?.id}/promotions`;
        }
    },
    methods: {
        showViewModal(item) {
            this.showSelectedItemId = item?.id || null;
            this.$bvModal.show(this.showModalId);
        },
        showFormModal(item) {
            this.formSelectedItemId = item?.id || null;
            this.$bvModal.show(this.formModalId);
        },
        showDiscountCodesModal(item) {
            this.showSelectedPromotion = item || null;
            this.$bvModal.show(this.showDiscountCodeModalId);
        },
        onSuccess() {
            this.$refs.collaboratorPromotionTable.refresh();
        },
        formatter(field, item) {
            switch (field) {
                case 'status':
                    return Status.getStatus({ itemStatus: item?.status, type: 'promotion_status', html: true });
                default:
                    return '';
            }
        },
    }
};
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <PromotionForm :modal-id="formModalId" :id="formSelectedItemId" :collaboratorId="collaborator?.id" @onSuccess="onSuccess"></PromotionForm>
                    <PromotionDiscountCodesShow :modal-id="showDiscountCodeModalId" :promotion="showSelectedPromotion"></PromotionDiscountCodesShow>
                    <CustomTable 
                        v-if="collaborator"
                        :url="tableUrl"
                        :fields="table.fields"
                        :params="table.params"
                        ref="collaboratorPromotionTable"
                        :formatted-fields="table.formatted_fields"
                        :formatter="formatter"
                        :show-view="false"
                        :show-edit="true"
                        :showSoftDelete="false"
                        @show="showViewModal"
                        @edit="showFormModal"
                        :show-modal-id="showModalId"
                    >
                        <template v-slot:header-actions>
                            <AddButton @click="showFormModal"
                                v-has-permission="{ permission: 'promotions.create', action: 'disable' }"></AddButton>
                        </template>
                        <template #cell-created_at="{ item }">
                            <span v-format-date="item?.created_at"></span>
                        </template>
                        <template #cell-title="{ item }">
                            <div :title="item?.title" v-b-tooltip.hover>{{ item?.title | truncateText(60) }}</div>
                        </template>
                        <template #cell-featured="{ item }">
                            <featured-icon :featured="item?.featured"></featured-icon>
                        </template>
                        <template v-slot:before-custom-actions="slotProps">
                            <a href="javascript:void(0);" class="mr-3 text-primary" title="Gestionar códigos de descuento"
                                @click="showDiscountCodesModal(slotProps.row?.item)">
                                <i class="mdi mdi-brightness-percent font-size-18"></i>
                            </a>
                        </template>
                    </CustomTable>
                </div>
            </div>
        </div>
    </div>
</template>