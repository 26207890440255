<script>
// import ContractForm from '@/views/pages/collaborators/forms/contract-form.vue';
/**
 * Collaborators Component
 */
export default {
    props: {
      collaborator: {
            type: Object,
            default: () => {}
        },
    },
    components: {
        // ContractForm
    },
    data() {
        return {
            formModalId:'contract-form-modal',
            formSelectedItemId:null
        };
    },
    mounted(){
    },
    computed:{
        isAdmin() {
            return this.$store.getters['permission/isAdmin']();
        },
    },
    methods: {
        admContract() {
            this.$bvModal.show(this.formModalId);
        }
    }
};
</script>

<template>
    <div class="row">
        <!-- <ContractForm :modal-id="formModalId" :id="collaborator?.contract?.id" :collaborator-id="collaborator?.id" @onSuccess="onSuccess"></ContractForm> -->
        <div class="col-6" v-has-permission="{ permission: 'collaborators.contract.view', action: 'remove' }">
            <div class="card h-100">
                <div class="card-body">                            
                    <h4 class="card-title mb-4">Contrato</h4>

                    <b-skeleton-wrapper :loading="$store.state.loading.loading">
                            <template #loading>
                                <b-card>
                                    <b-skeleton width="75%"></b-skeleton>
                                    <b-skeleton width="50%"></b-skeleton>
                                    <b-skeleton width="85%"></b-skeleton>
                                    <b-skeleton width="45%"></b-skeleton>
                                    <b-skeleton width="50%"></b-skeleton>
                                    <b-skeleton width="60%"></b-skeleton>
                                </b-card>
                            </template>
                            <div v-if="collaborator?.contract">
                                <div class="mb-2">
                                    <h6>Número de contrato:</h6>
                                    {{ collaborator?.contract?.contract_number }}
                                </div>
                                <div class="mb-2 text-danger">
                                    <h6>Número de contrato legacy:</h6>
                                    {{ collaborator?.contract?.legacy_contract_number }}
                                </div>
                                <div>
                                    <h6>Fecha:</h6>
                                    {{ collaborator?.contract?.signature_date }}
                                </div>                      
                                <div>
                                    <h6>Fecha de modificación:</h6>
                                    {{ collaborator?.contract?.modification_date }}
                                </div>                      
                            </div>
                            <div v-else class="text-center">
                                <p class="text-muted">
                                    El contrato se generará una vez aprobado el colaborador
                                </p>
                                <!-- <a
                                    href="javascript:void(0);"
                                    class="text-success"
                                    @click="admContract()"
                                >
                                    <i class="mdi mdi-plus-circle font-size-18"></i> Generar contrato
                                </a> -->
                            </div>
                        
                    </b-skeleton-wrapper>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card h-100">
                <div class="card-body">                            
                    <h4 class="card-title mb-4">Dirección</h4>

                    <b-skeleton-wrapper :loading="$store.state.loading.loading">
                        <template #loading>
                            <b-card>
                                <b-skeleton width="75%"></b-skeleton>
                                <b-skeleton width="50%"></b-skeleton>
                                <b-skeleton width="85%"></b-skeleton>
                                <b-skeleton width="45%"></b-skeleton>
                                <b-skeleton width="50%"></b-skeleton>
                                <b-skeleton width="60%"></b-skeleton>
                            </b-card>
                        </template>
                        <div class="mb-2">
                            <div v-if="collaborator?.address">{{ collaborator?.address }}</div>
                            <div v-if="collaborator?.postcode">CP: {{ collaborator?.postcode }}</div>
                        </div>
                        <div class="mb-2">
                            <h6>Dirección de correo electrónico:</h6>
                            <a :href="'mailto:' + collaborator?.email">{{ collaborator?.email }}</a>
                        </div>
                        <div>
                            <h6>Móvil:</h6>
                            <a :href="'tel:' + collaborator?.mobile">{{ collaborator?.mobile }}</a>
                        </div>
                        <div>
                            <h6>Teléfono:</h6>
                            <a :href="'tel:' + collaborator?.phone">{{ collaborator?.phone }}</a>
                        </div>
                    </b-skeleton-wrapper>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card h-100">
                <div class="card-body">                            
                    <h4 class="card-title mb-4">Datos de inicio de sesión</h4>

                    <b-skeleton-wrapper :loading="$store.state.loading.loading">
                        <template #loading>
                            <b-card>
                                <b-skeleton width="75%"></b-skeleton>
                                <b-skeleton width="50%"></b-skeleton>
                            </b-card>
                        </template>
                        <div class="mb-2">
                            <h6>Nombre:</h6>
                            {{ collaborator?.user?.name }}
                        </div>
                        <div class="mb-2">
                            <h6>Usuario:</h6>
                            {{ collaborator?.user?.email }}
                        </div>
                    </b-skeleton-wrapper>
                </div>
            </div>
        </div>
  </div>
</template>